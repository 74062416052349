import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Recette avocat crevette - Top 5 des meilleures inspirations"
            description="Lorsque vient le temps de cuisiner, la fraîcheur et la saveur des aliments sont déterminants. Voyez 5 succulentes recettes à base de crevettes et d’avocats."
            fr={undefined}
            lawType=""
            specialtyText="">
            <p>
                Lorsque la température est chaude et humide, il est tout à fait
                possible de faire des recettes rafraîchissantes et santé sans
                laisser de côté les délicieuses saveurs. Pour ce faire, deux
                ingrédients seront mis en vedette: la crevette et l’avocat.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Les meilleures recettes à base d’avocat et de crevette"
                    alt=""
                />
            </p>
            <p>
                En effet, à l’aide de ces deux ingrédients, il vous est possible
                de céer des recettes avocat crevette originale et diversifiée.
                De plus ces deux vedettes culinaires ont plusieurs bénéfices
                nutritifs forts intéressants pour ceux et celles qui veulent
                manger santé et apprécier leur nourriture.{' '}
            </p>
            <p>
                Cependant, il peut être possible que vous n'ayez pas les
                aptitudes de Gordon Ramsay ou simplement le temps de cuisiner
                des recettes de haute voltige. Rassurez-vous, il vous sera
                possible d’avoir des recettes simples et efficaces.
            </p>
            <p>
                <strong>
                    Recette avocat vous offre son top 5 des meilleures recettes
                    à base d’avocat et de crevette!
                </strong>
            </p>
            <h2>1- La salade de fusilli crevettes et avocats</h2>
            <p>
                Pour cuisiner la salade de fusilli, crevettes et avocats pour
                deux personnes, vous aurez besoin des ingrédients suivants:
            </p>
            <ul>
                <li>
                    150 g de petites crevettes “cocktails” cuites, égouttées et
                    décortiquées,{' '}
                </li>
                <li>2 avocats, </li>
                <li>250 g de fusilli,</li>
                <li>1 citron mûr, </li>
                <li>2 cuillères à soupe d’huile d’olive, </li>
                <li>Poivre et sel, et</li>
                <li>Basilic (optionnel). </li>
            </ul>
            <p>
                Outre les ingrédients, il sera aussi nécessaire d’avoir en main
                certains ustensiles afin de pouvoir effectuer la recette. Donc
                ayez sur votre plan de travail 1 couteau aiguisé ainsi qu’une
                cuillère en bois, un bol ainsi qu’un dénoyauteur.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="La recette de salade de fusilli aux crevettes et à l’avocat"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Voici les 4 étapes à suivre pour préparer votre salade de
                    fusilli crevettes et avocats:
                </strong>
            </p>
            <p>
                Premièrement, il sera nécessaire de{' '}
                <strong>cuire les 250 g de fusillis dans un litre d’eau</strong>
                . Assurez-vous de bien saler l’eau et de cuire les pâtes jusqu’à
                ce qu'elles soient <em>al dente</em>, soit une pâte qui reste
                ferme sous la dent.
            </p>
            <p>
                Ensuite, il sera nécessaire de{' '}
                <strong>passer les pâtes sous l’eau froide</strong> jusqu’à ce
                qu’elles soient refroidies. Ensuite, prenez le temps de bien
                égoutter celles-ci et de les réserver pour la suite de la
                recette.{' '}
            </p>
            <p>
                Après avoir mis de côté les pâtes, vous aurez à{' '}
                <strong>couper les 2 avocats</strong> en dés et bien les
                mélanger dans un bol avec le citron. Par la suite, vous pourrez
                ajouter les crevettes ainsi que l’huile d’olive, le basilic
                haché et les pâtes. Assurez-vous de saler et poivrer à votre
                goût.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Bon à savoir! </strong>Lorsque vous prenez
                            le jus de citron, il est judicieux de graduellement
                            l’ajouter afin d’ajuster la recette à votre palais.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Finalement, il ne vous reste plus qu’à bien mélanger le tout
                afin de s’assurer que tous les ingrédients se retrouvent dans
                chaque bouchée.{' '}
            </p>
            <p>
                Cette recette ne devrait
                <strong> prendre que 18 minutes au total</strong>, soit 10
                minutes de préparation et 8 minutes de cuisson. Tout ce qu’il
                vous reste à faire est de prendre le temps de savourer cette
                somptueuse salada.
            </p>
            <h2>
                2- Des verrines à l’avocats, aux crevettes ainsi qu’au fromage
                frais
            </h2>
            <p>
                Si vous êtes à la recherche d’une{' '}
                <strong>petite entrée facile à préparer </strong>et qui va
                impressionner vos convives, la verrine à l'avocat, aux crevettes
                et au fromage frais sera la recette qu’il vous faut! Pour
                réaliser cette recette pour 4 personnes, il vous faudra les
                ingrédients suivants:{' '}
            </p>
            <ul>
                <li>45 crevettes décortiquées de taille moyenne,</li>
                <li>150 g de fromage frai, </li>
                <li>3 avocats bien mûrs, </li>
                <li>2 citrons verts, </li>
                <li>Crème, et </li>
                <li>Ciboulette. </li>
            </ul>
            <p>
                Aussi, au niveau des instruments nécessaires, il vous faudra un
                four ainsi qu’un saladier, une fourchette et un lot de verrines.
            </p>
            <p>
                La première étape est d'
                <strong>écraser 2 avocats à l’aide d’une fourchette </strong>et
                d’asperger ces derniers du jus d’un demi-citron vert. Ensuite,
                il faudra répartir le mélange d’avocat qui va ressembler à une
                purée dans les 4 verrines. Vous devriez obtenir une hauteur
                d’environ <strong>2 cm</strong>. Gardez le reste du mélange afin
                d’ajouter une couche supplémentaire.{' '}
            </p>
            <p>
                Ensuite, la deuxième étape est de couper{' '}
                <strong>20 crevettes</strong> en petits morceaux et déposer ces
                derniers sur le mélange d’avocat. Par la suite, il faudra{' '}
                <strong>écraser le fromage</strong> et hacher la ciboulette.
                Mélanger les deux ingrédients et ajouter <strong>5 cl </strong>
                de crème afin de réduire l’épaisseur du mélange.
            </p>
            <p>
                La troisième étape est de répartir le mélange à base de fromage
                dans les 4 verrines afin d’atteindre une épaisseur de{' '}
                <strong>2 cm</strong>. Gardez le reste pour une couche
                supplémentaire. Après, prenez le{' '}
                <strong>troisième avocat</strong> et coupez celui-ci en petit
                dés. Puis, arroser ces derniers du jus d’une demi-lime.{' '}
            </p>
            <p>
                Prenez les morceaux d’avocats et mélangez-les avec des crevettes
                afin de répartir le tout sur le contenant des verrines afin
                d’atteindre une hauteur totale de 4 cm.{' '}
            </p>
            <p>
                Finalement, vous pouvez utiliser le reste de votre mélange
                d’avocat et celui de fromage frais afin de faire une dernière
                couche qui va remplir le reste des verrines.{' '}
            </p>
            <p>
                Enfin, pour décorer les verrines, vous pouvez prendre des
                crevettes entières avec quelques morceaux de ciboulette ainsi
                que des tranches de lime.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                <em>Le saviez-vous?</em>
                            </strong>{' '}
                            Il vous est tout à fait possible de mettre les
                            verrines au réfrigérateur si vous souhaitez préparer
                            ces derniers à l’avance.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Bref, pour l’ensemble de la recette, il faudra un temps{' '}
                <strong>d’environ 45 minutes</strong> afin de pouvoir préparer
                l’ensemble de la recette. Heureusement, il ne vous est pas
                nécessaire de considérer un temps de cuisson ou un temps de
                repos. Une fois la préparation complétée, les{' '}
                <strong>verrines sont prêtes</strong>.
            </p>
            <h2>3- Des wraps aux avocats et aux crevettes</h2>
            <p>
                Que ce soit pour vos enfants ou pour vous-mêmes, les wraps ont
                toujours la cote lorsque vient le temps de préparer un lunch ou
                une collation. Voici donc une recette facile de{' '}
                <strong>wraps aux crevettes et à l’avocat</strong>.
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="La recette de wraps aux crevettes et à l’avocat"
                    alt=""
                />
            </p>
            <p>
                Pour réaliser cette recette rapide pour{' '}
                <strong>4 personnes</strong>, il vous faudra les ingrédients
                suivants:
            </p>
            <ul>
                <li>300 g de crevettes cuites, </li>
                <li>3 avocats mûrs, </li>
                <li>Fromage frais (pour tartiner), </li>
                <li>4 tortillas souples, et </li>
                <li>Ciboulette. </li>
            </ul>
            <p>
                Dans le cas de cette recette, il ne vous sera pas nécessaire
                d’avoir des ingrédients autres qu’un couteau ainsi qu’une
                planche à découper.{' '}
            </p>
            <p>
                Tout d’abord, il sera nécessaire de{' '}
                <strong>tartiner les wraps</strong> au complet du fromage frais.
                Ensuite, vous aurez à ciseler la ciboulette et la répartir sur
                les wraps. Après, il faudra couper en dés les 3 avocats ainsi
                que les crevettes.{' '}
            </p>
            <p>
                Déposez l’ensemble des ingrédients sur les wraps et roulez
                fermement ces derniers à l’aide d’un
                <strong> papier film.</strong> Avant de couper les wraps en
                petites bouchées, il est fortement recommandé de laisser reposer
                les wraps au réfrigérateur{' '}
                <strong>pour une durée d’une heure</strong>.
            </p>
            <p>
                Outre le temps de repos, il faut prévoir, au maximum, une
                période de <strong>30 minutes</strong> afin de pouvoir compléter
                la recette et servir ces savoureuses bouchées à vos invités ou
                vos enfants.
            </p>
            <h2>4- Salade fraîche à l’avocat et aux crevettes</h2>
            <p>
                Vous souhaitez faire un repas rapide et savoureux en{' '}
                <strong>moins de 10 minutes</strong>? Voici une salade fraîche
                qui saura satisfaire vos papilles. Afin de préparer cette
                recette pour une personne, voici les ingrédients qu’il vous
                faut:{' '}
            </p>
            <ul>
                <li>350 g de crevettes décortiquées, </li>
                <li>2 avocats mûr, </li>
                <li>500 g de salade verte, </li>
                <li>1 lime, </li>
                <li>1 cuillère à soupe de moutarde, </li>
                <li>2 cuillères à soupe d’huile d’olive, </li>
                <li>2 cuillères à soupe de vinaigre, </li>
                <li>Curry, et </li>
                <li>Sel et poivre. </li>
            </ul>
            <p>
                La première étape est de <strong>laver votre salade</strong> et,
                ensuite, la déposer dans un saladier. Il faudra ensuite{' '}
                <strong>cuire vos crevettes</strong> dans une poêle et les
                ajoutez à votre salade. Après, vous pourrez couper vos avocats
                et les ajouter à la salade.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="La recette de salade fraîche aux crevettes et à l’avocat"
                    alt=""
                />
            </p>
            <p>
                Aussi, il sera nécessaire d’arroser la salade à l’aide de votre
                lime. Finalement, il sera nécessaire de mélanger, dans un petit
                bol, le vinaigre, l’huile d’olive, la moutarde et le curry afin
                de créer une vinaigrette que vous ajouterez à votre salade.{' '}
            </p>
            <p>
                Et voilà! En environ<strong> 6 minutes</strong> vous aurez une
                belle salade fraîche et savoureuse à déguster en plat principal
                ou en accompagnement.{' '}
            </p>
            <h2>5- Recette d’avocat aux crevettes</h2>
            <p>
                Finalement, la dernière recette proposée par Recette avocat est
                son <strong>avocat aux crevettes</strong>. Dans cette recette,
                il faudra d’abord préparer les ingrédients pour{' '}
                <strong>la mayonnaise</strong>, soit:{' '}
            </p>
            <ul>
                <li>1 jaune d’œuf, </li>
                <li>½ cuillère à soupe de ketchup, </li>
                <li>½ cuillère à soupe de vinaigre,</li>
                <li>3 cuillères à soupe d’huile d’olive, </li>
                <li>½ pointe de piment de cayenne, </li>
                <li>½ cuillère à soupe de cognac, </li>
                <li>1 cuillère à soupe de moutarde, et </li>
                <li>Sel.</li>
            </ul>
            <p>
                Également, il faudra prévoir les 4 ingrédients suivants afin de
                pouvoir préparer votre recette d’avocat aux crevettes:
            </p>
            <ul>
                <li>250 de crevettes décortiquées, </li>
                <li>2 avocats, </li>
                <li>½ citron, et </li>
                <li>Quelques feuilles de salade (au choix).</li>
            </ul>
            <p>
                Ces ingrédients vont vous permettre de préparer la recette pour
                4 personnes. Tout d’abord, pour la{' '}
                <strong>préparation de mayonnaise</strong>, il faudra, dans un
                bol, mélanger le jaune d’œuf avec le piment de cayenne, le
                vinaigre, la moutarde ainsi que le sel.{' '}
            </p>
            <p>
                Ensuite, vous aurez à tranquillement incorporer l’huile afin de
                faire monter la mayonnaise. Une fois complétée, ajouter le
                ketchup et le cognac. À cette étape,{' '}
                <strong>votre mayonnaise est complète.</strong>
            </p>
            <p>
                Après, il vous faudra couper les avocats en deux morceaux.
                Assurez-vous de retirer le noyau et de découper la chair en dés
                qui sera arrosée du demi-citron. Mélangez les morceaux d’avocat
                avec les crevettes ainsi que la moitié de la mayonnaise.
            </p>
            <p>
                Puis déposer une feuille de salade dans l’avocat que vous allez
                garnir du mélange jusqu’à ce qu’il soit à moitié plein. Vous
                pourrez utiliser le reste des crevettes pour décorer la surface
                de la préparation avec le reste de la mayonnaise.{' '}
            </p>
            <p>
                Finalement, il ne reste plus qu’à laisser reposer le tout
                pendant au moins <strong>30 minutes</strong> dans le
                réfrigérateur.{' '}
            </p>
            <h2>
                Voici le top 5 des meilleures recettes à base d’avocats et de
                crevettes
            </h2>
            <p>
                Même si ces recettes sont très savoureuses et, surtout, faciles
                à préparer, il n’en reste pas moins qu’il est question de deux
                ingrédients qui se complètent parfaitement. Par conséquent, il
                est tout à fait possible d'être original et de créer votre
                propre recette.{' '}
            </p>
            <p>
                Dans ce cas, il reste préférable de rester simple dans la
                sélection des ingrédients et de s’assurer de mettre en vedette
                l’avocat et la crevette afin de faire ressortir la fraîcheur et
                les saveurs de ces derniers.{' '}
            </p>
            <p>
                <strong>
                    Tout ce qu’il vous reste à faire est de vous mettre à la
                    tâche et de commencer à cuisiner dès maintenant!
                </strong>
            </p>
        </SeoPage>
    )
}

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "recette-avocat-crevette/recette-avocat-crevette.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "recette-avocat-crevette/fusilli-crevette-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "recette-avocat-crevette/wraps-crevette-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "recette-avocat-crevette/salade-crevette-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
